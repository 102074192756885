/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    Card_mobile_image1: file(relativePath: { eq: "loodgieter/boiler-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Card_mobile_image2: file(relativePath: { eq: "loodgieter/boiler-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Card_mobile_image3: file(relativePath: { eq: "loodgieter/boiler-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ribbon_mobile_image: file(
      relativePath: { eq: "loodgieter/loodgieter-6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ribbon_desktop_image: file(
      relativePath: { eq: "loodgieter/loodgieter-6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Boilers`,
  textWithLink: () => (
    <>
      <p>
        Een boiler zorgt voor snel en royaal warmwatercomfort in de keuken en
        badkamer. U bent bij ons aan het juiste adres voor de levering en
        installatie van een keukenboiler, elektrische boiler, gasgestookte
        boiler of zonneboiler. Wij adviseren u graag welk type het meest
        geschikt is voor uw situatie.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <AniLink fade to='/loodgieterswerk' className='ml-sm-4x'>
      <Button
        role='button'
        variant='contained'
        className='btn btn--secondary heading__button'
      >
        <i className='material-icons mr-1x'>link</i>Terug naar overzicht
      </Button>
    </AniLink>
  ),
};

const Boilers = ({ data, path, location }) => {
  const seo = {
    title: `Boilers`,
    description: `Een boiler zorgt voor snel en royaal warmwatercomfort in de keuken en badkamer. U bent bij ons aan het juiste adres voor de levering en installatie van een boiler`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.Card_mobile_image1.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Elektrische (keuken)boilers',
    inner:
      'Een elektrische boiler verwarmt zelfstandig water met behulp van elektriciteit. U kiest voor deze boiler wanneer u lokaal geregelde verwarming heeft en meer of sneller warm water in bijvoorbeeld de keuken wilt. Het voordeel is dat deze boiler overal geplaatst kan worden waar een aansluiting van elektriciteit en water aanwezig is.',
  };

  const CardHorizontal2 = {
    textFirst: true,
    noSpace: true,
    img: data.Card_mobile_image2.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Gasgestookte boilers',
    inner: `Een indirect gestookte boiler kan op iedere cv-ketel worden aangesloten en is geschikt voor een zeer grote warmtevraag. Dit type boiler brengt voorverwarmd water naar de gewenste temperatuur, waardoor er dus altijd warm water op voorraad is. Wanneer er kleine hoeveelheden warm water wordt afgetapt, hoeft de ketel niet aan te slaan waardoor er ook nog eens energie wordt bespaard.`,
  };

  const CardHorizontal3 = {
    textFirst: false,
    noSpace: true,
    img: data.Card_mobile_image3.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Zonneboiler',
    inner: `Zoals de naam al doet vermoeden, maakt een zonneboiler gebruik van de straling van het zonlicht voor de verwarming van tapwater. Gemiddeld kunt u met een zonneboilersysteem 50% op uw jaarlijkse kosten voor warm water besparen. Deze oplossing is dus niet alleen goed voor het milieu, maar ook voor uw portemonnee.`,
  };

  const sources = [
    data.Ribbon_mobile_image.childImageSharp.fluid,
    {
      ...data.Ribbon_desktop_image.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Voor al uw boilers`,
    pitchLine: `Voor elke situatie een passende oplossing.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        CardHorizontal2={CardHorizontal2}
        CardHorizontal3={CardHorizontal3}
        textBottom={textBottom}
      />
    </>
  );
};

Boilers.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Boilers;
